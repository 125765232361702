import React from 'react'
import Header from './header'

import '../styles/layout.css'
import styles from './layout.module.css'

const Layout = ({ children, companyInfo, onHideNav, onShowNav, showNav, siteTitle }) => (
  <>
    <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
    <div className={styles.content}>{children}</div>
    <footer className={styles.footer}>
      <div className={styles.footerWrapper}>
        <div className={styles.companyName}>
          Tipp Consulting
        </div>

        <div className={styles.siteInfo}>
          ©Copyright <script type="text/javascript">
                      document.write(new Date().getFullYear());
                    </script>
        </div>
      </div>
    </footer>
  </>
)

export default Layout

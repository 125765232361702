import React from 'react'
import ReactDOM from 'react-dom'
import Icon from './icons/group-6.svg'
import { cn } from '../lib/helpers'

import {faLinkedinIn} from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

import Container from './container.js'
import styles from './header.module.css';

const Header = ({ onHideNav, onShowNav, showNav, siteTitle }) => (
  <div className={styles.root}>
    <div className={styles.wrapper}>
      <div className={styles.imagebox}>
        <img role="presentation" alt="Tipp Consulting" src={Icon} className = {styles.icon}/>
      </div>
      <nav className={cn(styles.nav, showNav && styles.showNav)}>
        <ul>
          <li>
            <Link to='AboutCompanyLink' smooth={true} duration={500}>Services</Link>
          </li>
          <li>
            <Link to='AboutMeLink' smooth={true} duration={500}>About</Link>
          </li>
          <li>
            <Link to='GetInTouchLink' smooth={true} duration={500}>Contact</Link>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/aggie-mikulski-51a27010/" target="_blank" className={styles.linkedin}>
              <span className={styles.circle}>
                <FontAwesomeIcon icon={faLinkedinIn} />
              </span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
)

export default Header
